import {
  fetchGet,
  fetchPost,
  fetchPut,
  fetchDelete,
  fetchGetFile,
  SERVER_ENDPOINT,
} from './index';
import moment from 'moment';
// User related api

export const userSignIn = async (user_data) => {
  return await fetchPost(`${SERVER_ENDPOINT}/api/users/userSignIn`, user_data);
};

export const userUpdateField = async (user_data) => {
  return await fetchPut(
    `${SERVER_ENDPOINT}/api/users/userUpdateField/?id=${user_data.id}`,
    user_data,
  );
};

export const userUpdateFirgun = async (user_data) => {
  return await fetchPut(
    `${SERVER_ENDPOINT}/api/users/userUpdateFirgun/?id=${user_data.id}`,
    user_data,
  );
};

export const userAddField = async (user_data) => {
  return await fetchPost(
    `${SERVER_ENDPOINT}/api/users/userAddField/?id=${user_data.id}`,
    user_data,
  );
};

export const fetchVerifiedUser = async (token, user_id) => {
  return await fetchGet(
    `${SERVER_ENDPOINT}/api/users/validateUser/?id=${user_id}`,
    null,
    token,
  );
};

export const fetchUserFields = async (user_id) => {
  return await fetchGet(
    `${SERVER_ENDPOINT}/api/users/userFields/?id=${user_id}`,
  );
};
export const getLoggedUserFields = async (user_id) => {
  return await fetchGet(
    `${SERVER_ENDPOINT}/api/users/getLoggedUserFields/?id=${user_id}`,
  );
};

export const fetchUsersByName = async (str) => {
  return await fetchGet(`${SERVER_ENDPOINT}/api/users/?s=${str}`);
};

export const fetchUnAnsweredSurveys = async (user_id) => {
  return await fetchGet(`${SERVER_ENDPOINT}/api/users/surveys/?id=${user_id}`);
};

// -------------General API for different post types --------------------------------
export const searchAll = async (str) => {
  return await fetchGet(`${SERVER_ENDPOINT}/api/posts/searchAll?s=${str}`);
};

export const fetchPostsCategories = async (type) => {
  return await fetchGet(
    `${SERVER_ENDPOINT}/api/posts/getCategories?type=${type}`,
  );
};

export const fetchAllPosts = async (post_type, str, category_id) => {
  return await fetchGet(
    `${SERVER_ENDPOINT}/api/posts/getAllPosts?post_type=${post_type}&str=${
      str ? str : ''
    }&category_id=${category_id ? category_id : ''}`,
  );
};

export const fetchFuturePosts = async (
  post_type,
  per_page,
  page,
  category_ids,
  main_cat_id,
) => {
  return await fetchGet(
    `${SERVER_ENDPOINT}/api/posts/getFuturePosts?post_type=${post_type}${
      per_page ? '&per_page=' + per_page : ''
    }${page ? '&page=' + page : ''}${
      category_ids ? '&category_ids=' + category_ids : ''
    }${main_cat_id ? '&main_cat_id=' + main_cat_id : ''}`,
  );
};
// export const fetchFuturePosts = async (
//   post_type,
//   per_page,
//   page
// ) => {
//   return await fetchGet(
//     `${SERVER_ENDPOINT}/api/posts/getFuturePosts?post_type=${post_type}${
//       per_page ? '&per_page=' + per_page : ''
//     }${page ? '&page=' + page : ''}`
//   );
// };

export const fetchAllFoodOrders = async () => {
  return await fetchGet(`${SERVER_ENDPOINT}/api/posts/getAllFoodOrders`);
};

// Get a number of resent posts
export const fetchRecentPosts = async (
  post_type,
  number_of_posts,
  page,
  category,
) => {
  return await fetchGet(
    `${SERVER_ENDPOINT}/api/posts/getRecentPosts?post_type=${post_type}&number_of_posts=${number_of_posts}&page=${page}${
      category ? '&category=' + category : ''
    }`,
  );
};

export const fetchPostsByIds = async (post_ids, post_type, perPage, page) => {
  return await fetchGet(
    `${SERVER_ENDPOINT}/api/posts/getPostsByIds?ids=${post_ids?.join(
      ',',
    )}&post_type=${post_type}${perPage ? '&per_page=' + perPage : ''}${
      page ? '&page=' + page : ''
    }`,
  );
};

// fetch specific post by id
export const fetchPostById = async (id, post_type) => {
  return await fetchGet(
    `${SERVER_ENDPOINT}/api/posts/getPostById?id=${id}&post_type=${post_type}`,
  );
};

// fetch posts by category id
export const fetchPostsByCategoryId = async (category_id, post_type) => {
  return await fetchGet(
    `${SERVER_ENDPOINT}/api/posts/getPostsByCategoryId?category_id=${category_id}&post_type=${post_type}`,
  );
};
//   Post new post
export const postPost = async (post_data) => {
  return await fetchPost(`${SERVER_ENDPOINT}/api/posts/`, post_data);
};

export const addUserToReadPost = async (data) => {
  return await fetchPut(`${SERVER_ENDPOINT}/api/posts/addUserToReadPost`, data);
};

export const addRepeaterRow = async (data) => {
  return await fetchPut(`${SERVER_ENDPOINT}/api/posts/addRepeaterRow`, data);
};

export const deletePost = async (data) => {
  return await fetchDelete(
    `${SERVER_ENDPOINT}/api/posts/deletePost?id=${data.id}&type=${data.type}`,
  );
};

// ------- end of general posts API ----------------------------------------

export const fetchTodaysBirthdays = async () => {
  return await fetchGet(`${SERVER_ENDPOINT}/api/users/todaysBirthdays`);
};

export const fetchOptionsFields = async () => {
  return await fetchGet(`${SERVER_ENDPOINT}/api/general/optionsFields/`);
};

export const fetchMenu = async (menu_id) => {
  return await fetchGet(
    `${SERVER_ENDPOINT}/api/general/getMenu/?id=${menu_id}`,
  );
};

//  Media Api
export const uploadMedia = async (mediaArray) => {
  const formData = new FormData();

  mediaArray.forEach((media) => {
    formData.append('files', media);
  });

  return await fetchPost(
    `${SERVER_ENDPOINT}/api/media/`,
    formData,
    'multipart/form-data',
  );
};

export const getSvg = async (url) => {
  return await fetchGet(`${SERVER_ENDPOINT}/api/media/svg/?url=${url}`);
};

export const downloadFile = async (url) => {
  return await fetchGetFile(
    `${SERVER_ENDPOINT}/api/media/download/?url=${url}`,
  );
};

// Get page info using wp api
export const fetchPage = async (page_id) => {
  return await fetchGet(`${SERVER_ENDPOINT}/api/pages/getPage?id=${page_id}`);
};

// replace _ to " " in the titles
const backSanitizeTitle = (title) => title.replace(/_/g, ' ');

// send the user data from event register form
export const sendRegisterData = async (id, fieldsData, userId, qty = 1) => {
  let formAnswersData = '';

  if (fieldsData.length) {
    fieldsData.forEach((data, i) => {
      if (
        data['גיל'][0].value &&
        data['מידת_חולצת_הילד'][0].value &&
        data['שם'][0].value
      ) {
        Object.keys(data).forEach((key, index, array) => {
          const helper = data[key].filter((answer) => {
            if (typeof answer === 'object') {
              // if ((answer.value && answer.value.length) || answer.length)
              // return answer.value ? answer.value : answer;
              return answer.value ? answer.value : '';
            } else {
              if (answer !== 'notRequired') return answer;
            }
          });

          formAnswersData +=
            `{
						"acf_fc_layout": "multiple",
						"title": "${backSanitizeTitle(key)}",
						"answers": [` +
            helper.map((answer) => {
              return `{
							"answer": "${answer.value ? answer.value : answer}"
						}`;
            }) +
            `]}${
              index !== array.length - 1 || i !== fieldsData.length ? ',' : ''
            }`;
        });
      }
    });
  } else {
    Object.keys(fieldsData).forEach((key, index, array) => {
      const helper = fieldsData[key].filter((answer) => {
        if (
          typeof answer === 'object' &&
          ((answer.value && answer.value.length) || answer.length)
        ) {
          return answer.value ? answer.value : answer;
        }
      });

      formAnswersData +=
        `{
				"acf_fc_layout": "multiple",
				"title": "${backSanitizeTitle(key)}",
				"answers": [` +
        helper.map((answer) => {
          return `{
					"answer": "${answer.value ? answer.value : answer}"
				}`;
        }) +
        `]}${index !== array.length - 1 ? ',' : ''}`;
    });
  }

  if (formAnswersData[formAnswersData.length - 1] === ',') {
    formAnswersData = formAnswersData.substring(0, formAnswersData.length - 1);
  }

  const objectData = `
		{
			"user": ${userId},
			"register_questions": [
				${formAnswersData}
			],
            "qty":${qty}
		}
	`;

  const post_data = {
    field_name: 'registered',
    field_value: [JSON.parse(objectData)],
    id: id,
  };

  return await fetchPut(
    `${SERVER_ENDPOINT}/api/posts/addRepeaterRow/?id=${id}`,
    post_data,
  );
};

export const updatePost = async (id, post_data, post_type) => {
  return await fetchPut(
    `${SERVER_ENDPOINT}/api/posts/updatePost/?id=${id}&post_type=${post_type}`,
    post_data,
  );
};

// update a post by slug if post exist if not it creates one
export const updatePostBySlug = async (slug, post_data, post_type) => {
  return await fetchPut(
    `${SERVER_ENDPOINT}/api/posts/updateFoodOrder?slug=${slug}&post_type=${post_type}`,
    post_data,
  );
};

export const getFoodOrdersByUserId = async (id, perPage, page) => {
  return await fetchGet(
    `${SERVER_ENDPOINT}/api/posts/getFoodOrdersByUserId?id=${id}${
      perPage ? '&per_page=' + perPage : ''
    }&page=${page}`,
  );
};

export const deleteFoodOrderByUserId = async (order) => {
  return await fetchPut(
    `${SERVER_ENDPOINT}/api/posts/deleteFoodOrderByUserId`,
    order,
  );
};
// Galleries
export const voteForImage = async (data) => {
  return await fetchPut(`${SERVER_ENDPOINT}/api/galleries/voteForImage`, data);
};

export const removeImageFromGallery = async (data) => {
  return await fetchDelete(
    `${SERVER_ENDPOINT}/api/galleries/removeImageFromGallery`,
    data,
  );
};

// send the user answers from surveys
export const sendSurveyAnswers = async (id, fieldsData, userId) => {
  let surveyAnswersData = '';

  Object.keys(fieldsData).forEach((key, index, array) => {
    const helper = fieldsData[key].filter((answer) => {
      if ((answer.value && answer.value.length) || answer.length) {
        return answer.value ? answer.value : answer;
      }
    });

    surveyAnswersData +=
      `{
			"acf_fc_layout": "multiple",
			"title": "${backSanitizeTitle(key)}",
			"answers": [` +
      helper.map((answer) => {
        return `{
							"answer": "${answer.value ? answer.value : answer}"
						}`;
      }) +
      `]}${index !== array.length - 1 ? ',' : ''}`;
  });

  const time = moment().format('DD/MM/YYYY HH:mm A');

  const objectData = `
		{
			"user": ${userId},
			"survey_answers": [
				${surveyAnswersData}
			],
			"sending_time": "${time}"
		}
	`;

  const post_data = {
    field_name: 'answers_repeater',
    field_value: [JSON.parse(objectData)],
    id: id,
  };

  return await fetchPut(
    `${SERVER_ENDPOINT}/api/posts/addRepeaterRow/?id=${id}`,
    post_data,
  );
};

// send new friend cv
export const sendFriendCv = async (name, fileId, userId) => {
  return await fetchPost(
    `${SERVER_ENDPOINT}/api/posts/createNewFriendCv?user_id=${userId}&name=${name}`,
    {
      slug: name,
      user_id: userId,
      file: fileId,
    },
  );
};

// send load pdf
export const sendLoadPdf = async (userId, userName, file) => {
  return await fetchPost(`${SERVER_ENDPOINT}/api/posts/sendLoanPdfByEmail`, {
    user_id: userId,
    user_name: userName,
    file: file,
  });
};

// Send general mail
export const sendMail = async (to, subject, message) => {
  return await fetchPost(`${SERVER_ENDPOINT}/api/general/sendMail`, {
    to,
    subject,
    message,
  });
};

// Fluent Forms API
export const getForm = async (id) => {
  return await fetchGet(`${SERVER_ENDPOINT}/api/general/getForm?id=${id}`);
};

export const submitForm = async (form_data) => {
  return await fetchPost(
    `${SERVER_ENDPOINT}/api/general/submitForm`,
    form_data,
  );
};

// send push notification data to backend
export const sendPushData = async (pushData) => {
  return await fetchPost(
    `${SERVER_ENDPOINT}/api/general/sendPushData`,
    pushData,
  );
};

export const fetchAllRegisteredEvents = async (isSummerCamp) => {
  return await fetchGet(
    `${SERVER_ENDPOINT}/api/posts/getAllRegisteredEvents?isSummerCamp=${isSummerCamp}`,
  );
};

export const fetchWeeklyMenuUrl = async () => {
  return await fetchGet(`${SERVER_ENDPOINT}/api/posts/getWeeklyMenuUrl`);
};

export const fetchUserMessages = async (email) => {
  return await fetchGet(
    `${SERVER_ENDPOINT}/api/users/getUserMessages?email=${email}`,
  );
};

export const fetchOfficials = async (email) => {
  return await fetchGet(`${SERVER_ENDPOINT}/api/posts/getOfficials`);
};

export const fetchRequiredSignPosts = async () => {
  return await fetchGet(`${SERVER_ENDPOINT}/api/posts/getRequiredSignPosts`);
};
